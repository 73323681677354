import { DateRange } from '@types';
import dayjs, { Dayjs } from 'dayjs';
import { momentMonthYearFormat } from '../il8n/moment-il8n';
import { Logger } from '../logger';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import localeData from 'dayjs/plugin/localeData';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(localeData);
dayjs.extend(utc);
dayjs.extend(quarterOfYear);

const logger = Logger.getLogger('date-utils');

/**
 * Return locale based day, when given std format day eg YYYY-MM-DD
 * If not std format will fallback to detecting locale format as set in moment
 *
 * WARNING: only for use when dealing with javascript created dates, all other displayed dates
 * SHOULD come from java backend, if for no other reason than moment is slow at 4-5ms per conversion
 *
 * @param val date in format locale or YYYY-MM-DD
 * @returns  val locale day format DD/MM/YYYY
 */
export function formatStdDateToLocaleDay(val: string, useDash = false): string {
  if (!val || val === '-') {
    return useDash ? '—' : '-';
  }
  const stdDateFormatMatch = val.match(/^([0-9]{4})-([0-9]{2})-([0-9]{2})/);
  if (stdDateFormatMatch) {
    const valAsMoment = dayjs(val, 'YYYY-MM-DD');
    if (valAsMoment.isValid()) {
      return valAsMoment.format('L');
    }
  }

  const localeMoment = dayjs(val, 'L');
  if (localeMoment && localeMoment.isValid()) {
    logger.warn('Locale Conversion of date:', val);
    return localeMoment.format('L');
  }
  return val;
}

/**
 * Return locale based month, when given std format day eg YYYY-MM
 * @param val date in format locale or YYYY-MM
 * @returns val locale day format MM/YYYY
 */
export function formatStdDateToLocaleMonth(
  val: string,
  useDash = false
): string {
  if (!val || val === '-') {
    return useDash ? '—' : '-';
  }
  const monthYearFormat = momentMonthYearFormat();
  const stdDateFormatMatch = val.match(/^([0-9]{4})-([0-9]{2})/);
  if (stdDateFormatMatch) {
    const valAsMoment = dayjs(val, 'YYYY-MM');
    if (valAsMoment.isValid()) {
      return valAsMoment.format(monthYearFormat);
    }
  }

  const localeMoment = dayjs(val, 'L');
  if (localeMoment && localeMoment.isValid()) {
    logger.warn('Locale Conversion of date:', val);
    return localeMoment.format(monthYearFormat);
  }
  return val;
}

/**
 * Return UTC standard unix timestamp, when given std format day eg YYYY-MM-DD
 *
 * @param val date in format locale or YYYY-MM-DD
 * @returns UTC unix timestamp
 */
// export function formatStdDateToUnixTimeStamp(val: string): number {
//   if (!val || val === '-') {
//     return null;
//   }
//   const stdDateFormatMatch = val.match(/^([0-9]{4})-([0-9]{2})-([0-9]{2})/);
//   if (!stdDateFormatMatch) {
//     return null;
//   }
//   const year = stdDateFormatMatch[1];
//   const month: any = stdDateFormatMatch[2] - 1;
//   const day = stdDateFormatMatch[3];
//   const date = Date.UTC(year, month, day, 0, 0);
//   return date.valueOf();
// }

/**
 * Return Standard Year, when given std format day eg YYYY-MM-DD
 * If not std format will fallback to detecting locale format as set in moment
 *
 * WARNING: only for use when dealing with javascript created dates, all other displayed dates
 * SHOULD come from java backend, if for no other reason than moment is slow at 4-5ms per conversion
 *
 * @param val date in format locale or YYYY-MM-DD
 * @returns  val std year format YYYY
 */
export function formatStdDateToStdYear(val: string, useDash = false): string {
  if (!val || val === '-') {
    return useDash ? '—' : '-';
  }
  const stdDateFormatMatch = val.match(/^([0-9]{4})-([0-9]{2})-([0-9]{2})/);
  if (stdDateFormatMatch) {
    return stdDateFormatMatch[1];
  }

  const localeMoment = dayjs(val, 'L');
  if (localeMoment && localeMoment.isValid()) {
    logger.warn('Locale Conversion of date:', val);
    return localeMoment.format('YYYY');
  }
  return val;
}

/**
 * Return std format day eg YYYY-MM-DD, when given locale based day
 *
 * WARNING: only for use when dealing with javascript created dates, all other displayed dates
 * SHOULD come from java backend, if for no other reason than moment is slow at 4-5ms per conversion
 *
 * @param val date in format locale
 * @returns val locale day format YYYY-MM-DD
 */
export function formatLocaleDateToStdDate(
  val: string,
  useDash = false
): string {
  if (!val || val === '-') {
    return useDash ? '—' : '-';
  }
  const stdDateFormatMatch = val.match(/^([0-9]{4})-([0-9]{2})-([0-9]{2})/);
  if (stdDateFormatMatch) {
    logger.warn('Std Conversion of date:', val);
    const valAsMoment = dayjs(val, 'YYYY-MM-DD');
    return valAsMoment.format('YYYY-MM-DD');
  }

  let localeMoment = dayjs(val, 'L');
  // COREWEB-4801 - allow conversion for dates without day.
  if (val.match(/^([0-9]{2})\/([0-9]{4})/)) {
    localeMoment = dayjs(val, 'MM/YYYY');
  }
  if (val.match(/^([0-9]{2})\.([0-9]{4})/)) {
    localeMoment = dayjs(val, 'MM.YYYY');
  }
  if (localeMoment) {
    return localeMoment.format('YYYY-MM-DD');
  }
  return val;
}

/**
 * Give Locale Time, e.g. when given 6 + yy(years) = 6 years(en-gb) or 6 Jahren(de-de)
 * @param amountOfUnit e.g. 6
 * @param units e.g. yy (years), can make integer by post fixing -integer, e.g. dd-integer
 * @returns output e.g. 6 Jahren
 */
// export function convertUnitsToTimePeriod(amountOfUnit: number|string, units: moment.RelativeTimeKey): string {
//   if (!amountOfUnit || amountOfUnit === '-' || amountOfUnit === '—') {
//     return '—';
//   }
//   // COREWEB-4818 integer support
//   const integerMatching = units.match(/^(.*)-integer$/);
//   if (integerMatching) {
//     const parsedFloat = fromLocaleNumber(amountOfUnit);
//     if (!parsedFloat) {
//       return '—';
//     }
//     amountOfUnit = Math.floor(parsedFloat);
//     units = integerMatching[1];
//   }
//   return momentTimePeriodFormat(moment.locale(), Number(amountOfUnit), units, false);
// }

/**
 * Returns date in format 'month name' 'year'
 */
export function momentMonthAndYear(val: string): string {
  let standardDate;
  const stdDateFormatMatch = val.match(/^([0-9]{4})-([0-9]{2})-([0-9]{2})/);
  const stdDateFormatMatchMth = val.match(/^([0-9]{4})-([0-9]{2})/);
  if (val.match(/^([0-9]{2}).([0-9]{4})/)) {
    standardDate = val.slice(3) + '-' + val.slice(0, 2);
    return dayjs(standardDate).format('MMMM YYYY');
  }
  standardDate =
    !!stdDateFormatMatch || !!stdDateFormatMatchMth
      ? val
      : formatLocaleDateToStdDate(val);
  return !!standardDate.match(/^([0-9]{4})-([0-9]{2})/)
    ? dayjs(standardDate).format('MMMM YYYY')
    : 'Invalid date';
}

/**
 * Return last month day of given date
 */
export function getLastDayOfMonth(standardDate: string, format = 'DD'): string {
  return !!standardDate && standardDate.match(/^([0-9]{4})-([0-9]{2})/)
    ? dayjs(standardDate).endOf('month').format(format)
    : 'Invalid date';
}

/**
 * Subtract one year from given date in format YYYY-MM-DD
 */
export function subtractOneYear(standardDate: string): string {
  return dayjs(standardDate).subtract(1, 'year').format('YYYY-MM-DD');
}

/**
 * @see http://momentjs.com/docs/#/parsing/string-format/
 * @see http://api.jqueryui.com/datepicker/#utility-formatDate
 */
// export function convertMomentToJqueryFormat(momentFormat: string): string {
//   return momentFormat
//     .replace('YYYY', 'yy') // 2014 4 or 2 digit year
//     .replace('YY', 'y') // 14 2 digit year
//     .replace('Y', 'na')// -25 Year with any number of digits and sign
//     .replace('Q', 'na')// 1..4 Quarter of year. Sets month to first month in quarter.
//     .replace('MMMM', 'ZZ') // part1 Jan..December Month name in locale set by moment.locale()
//     .replace('MMM', 'Z') // part1 Jan..December Month name in locale set by moment.locale()
//     .replace('MM', 'mm') // 1..12 Month number
//     .replace('M', 'm')
//     .replace('DDDD', 'oo') // 1..365 Day of year
//     .replace('DDD', 'o') // 1..365 Day of year
//     .replace('DD', 'dd') // 1..31 Day of month
//     .replace('D', 'd') // 1..31 Day of month
//     .replace('Do', 'na') // 1st..31st Day of month with ordinal
//     .replace('X', '@') // 1410715640.579 Unix timestamp
//     .replace('x', '@') // 1410715640579 Unix ms timestamp
//     // SAFE REPLACEMENTS
//     .replace('Z', 'M'); // part2 Jan..December Month name in locale set by moment.locale()
// }

/**
 * @see http://momentjs.com/docs/#/parsing/string-format/
 * @see http://api.highcharts.com/highcharts/tooltip.dateTimeLabelFormats/Highcharts.dateFormat
 * @see http://php.net/manual/en/function.strftime.php
 */
export function convertMomentToHighchartsFormat(momentFormat: string): string {
  return (
    momentFormat
      .replace('YYYY', 'Z') // PART 2014 4 or 2 digit year
      .replace('YY', '%y') // 14 2 digit year
      .replace('Y', 'na') // -25 Year with any number of digits and sign
      .replace('Q', 'na') // 1..4 Quarter of year. Sets month to first month in quarter.
      .replace('MMMM', '%B') // part1 Jan..December Month name in locale set by moment.locale()
      .replace('MMM', '%b') // part1 Jan..Dec Month name in locale set by moment.locale()
      .replace('MM', '%m') // 01..12 Month number
      .replace('M', '%m') // 1..12 Month number
      .replace('DDDD', '%j') // 1..365 Day of year
      .replace('DDD', '%j') // 1..365 Day of year
      .replace('DD', '%d') // 01..31 Day of month
      .replace('D', '%e') // 1..31 Day of month
      .replace('Do', 'na') // 1st..31st Day of month with ordinal
      .replace('X', '%s') // 1410715640.579 Unix timestamp
      .replace('x', '%s') // 1410715640579 Unix ms timestamp
      // SAFE REPLACEMENTS
      .replace('Z', '%Y')
  ); // PART 2 2014 4 or 2 digit year
}

/**
 * @see http://momentjs.com/docs/#/parsing/string-format/
 * @return in momentFormat
 */
export function getMomentLocaleFormat(): string {
  return dayjs.localeData().longDateFormat('L');
}

/**
 * @see http://api.jqueryui.com/datepicker/#utility-formatDate
 * @return in jqueryFormat
 */
// export function getJqueryLocaleFormat() {
//   return convertMomentToJqueryFormat(getMomentLocaleFormat());
// }

/**
 * @see http://api.jqueryui.com/datepicker/#utility-formatDate
 * @return  in jqueryFormat
 */
// export function getJqueryLocaleMonthYearFormat() {
//   return convertMomentToJqueryFormat(momentMonthYearFormat(moment.locale()));
// }

/**
 * @see http://api.highcharts.com/highcharts/tooltip.dateTimeLabelFormats/Highcharts.dateFormat
 * @see http://php.net/manual/en/function.strftime.php
 * @return in Highcharts dateFormat
 */
export function getHighchartsLocaleFormat(siteConfigDateFormat?: string) {
  return convertMomentToHighchartsFormat(
    siteConfigDateFormat || getMomentLocaleFormat()
  );
}

export function getTimeStampInMilliseconds(
  date: string,
  format: string
): number {
  return dayjs.utc(date, format).valueOf();
}

export function getStartOfQuarterInMilliseconds(): any {
  return dayjs().startOf('quarter');
}

export function getPreviousYearsDateRange(
  numberOfPreviousYears: number,
  date: string,
  dateFormat: string,
  language: string,
  expectedDateFormat: string,
  // NGC-7604 if inceptionDateStd passed, alter first start date
  inceptionDateStd?: string
): DateRange[] {
  const years: DateRange[] = [];
  let inceptionFlag = false;
  const asOfMoment = dayjs(date, dateFormat, language);
  if (asOfMoment.isValid()) {
    let inceptionMoment: Dayjs;
    if (inceptionDateStd) {
      inceptionMoment = dayjs(inceptionDateStd);
    }
    for (let year = 1; year <= numberOfPreviousYears; year++) {
      let startDate: Dayjs = asOfMoment.clone().subtract(year, 'years');
      // NGC-7604
      // if inception date was passed, and start date is before inception, use inception instead
      if (inceptionMoment && inceptionMoment.isAfter(startDate)) {
        startDate = inceptionMoment;
        inceptionFlag = true;
      }
      const endDate: string = asOfMoment
        .clone()
        .subtract(year - 1, 'years')
        .format(expectedDateFormat);
      years.push({ startDate: startDate.format(expectedDateFormat), endDate });
      if (inceptionFlag) {
        break;
      }
    }
  }
  return years;
}

export function getPreviousQuarters(
  numberOfPreviousQuarters: number,
  date: string,
  dateFormat: string,
  language: string,
  expectedQuartersFormat: string
): string[] {
  const quarters: string[] = [];
  const dateMoment = dayjs(date, dateFormat, language);

  if (dateMoment.isValid()) {
    for (let quarter = 1; quarter < numberOfPreviousQuarters; quarter++) {
      quarters.push(
        dateMoment
          .clone()
          .subtract(quarter, 'quarter')
          .endOf('quarter')
          .format(expectedQuartersFormat)
      );
    }
  }

  return quarters;
}

export function getDateYear(date: string, format: string): number {
  return dayjs(date, format).get('year');
}

export function getDateMonth(date: string, format: string): number {
  return dayjs(date, format).get('month');
}

export function subtractYears(
  date: string,
  format: string,
  years: number
): string {
  return dayjs(date).subtract(years, 'year').format(format);
}

export function getDate(date: string, format: string): string {
  return dayjs(date).format(format);
}
