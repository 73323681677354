import { CompanyInfo } from '@frk/eds-components';
import { IdentifierKey } from '@models';
import { PerformanceComponentId } from '@products/fund-performance/performance-component-id.enum';
import {
  ConfigurationCountry,
  ConfigurationLocale,
  ProductType,
} from '@products/types';
import {
  AssetClass,
  FundId,
  FundShareClassId,
  FundUmbrella,
  PortfolioComponentId,
  StatisticId,
  WebProductTaxonomy,
} from '../types';
import { SegmentId } from './segment.type';

export enum PerformanceType {
  AVG_ANNUAL = 'AVG_ANNUAL',
  CALENDAR_YEAR = 'CALENDAR_YEAR',
  CUMULATIVE = 'CUMULATIVE',
  DISCRETE = 'DISCRETE',
}
export type FundSectionLink = {
  link: string;
  labelKey: string;
};

export type GeneralConfig = {
  activeList?: FundShareClassId[];
  softLaunchList?: FundShareClassId[];
  softClosedList?: FundShareClassId[];
  noBenchmarkList?: FundId[];
  twoBenchmarkList?: FundId[];
  allBenchmarkList?: FundId[];
  hideYtdTotalReturnAtNav?: FundUmbrella[];
  showMDashForUnder1Year?: boolean;
  displayPerformanceDataForUnder1Year?: boolean;
  showMDashForUnder6Months?: boolean;
  suppress1And2StarMSRatings?: boolean;
  keyIdentifier?: Record<WebProductTaxonomy, IdentifierKey>;
  search?: any;
  labelOverrideList?: LabelOverride[];
  showAacr?: string[];
  defaultCalcType?: Record<ProductType, string>;
  additionalCalcType?: Record<ProductType, string>;
  nonTradedFunds?: FundId[];
  ytdTotalReturnNavMarketPrice?: boolean;
  // for activeList optimization
  activeListCommon?: number[]; // per segment
  fundHeaderLinks?: FundHeaderLink[];
};

export type FundHeaderLink = {
  displayText: string;
  redirectLink: string;
  iconType: string;
  tooltipContent: string;
  investmentVehicles: string[];
  fundList: FundId[];
  displayInStickyNavigation?: boolean;
  openInNewWindow?: boolean;
};

export type LabelOverride = {
  funds: FundId[];
  key: string; // label id. TODO: consider defining LabelId type
  value: string; // new label value
};

export type OverviewConfig = {
  riskFactorContentCategory?: string;
  ratings?: Record<WebProductTaxonomy, string[]>;
  showLinkToBios?: Record<WebProductTaxonomy, boolean>;
  showYearsOfExperience?: Record<WebProductTaxonomy, boolean>;
  fundInfoSuppressionList?: Record<WebProductTaxonomy, string[]>;
  showAllAssociatedBenchmarkInfo?: boolean;
  regulatorLinkURL?: string;
};
export type PerformanceConfig = {
  hideYTDForCumulativeAndCalendarYear?: boolean;
  dataRefreshRate?: Record<ProductType, Record<PerformanceType, string>>;
  ppssPerformanceViews?: Record<ProductType, PerformanceType[]>;
  hideCurrency?: string[];
  benchmarkOrder?: Record<WebProductTaxonomy, string[]>;
  suppressBenchmarkData?: Record<WebProductTaxonomy, string[]>;
  hideAddShLinkList?: WebProductTaxonomy[];
  showYtd1M3M6M?: boolean;
  cumulativeHypoChart?: string;
  cumulativeHistorical?: boolean;
  acHistorical?: boolean;
  showCharts1YearLess?: boolean;
  dynamicLabels?: boolean;
  showPeerGroupCategoryAverageAddition?: WebProductTaxonomy[];
  hideRiskStatisticsBenchmark?: boolean;
  showSinceInception?: boolean;
  suppressionSets?: PerformanceSuppressionSet[];
  suppress6m15yrPerformance?: string[];
  suppressPOPRow?: string[];
};

export type HoldingTableColumns = {
  id: string;
  assetClasses: AssetClass[];
  funds: FundId[];
  holdings: string[]; // ids for cols to show in table
};

export type PortfolioConfig = {
  benchmarkSuppression?: FundId[];
  showHoldingsDownload: boolean;
  statisticSuppressionSets?: StatisticSuppressionSet[];
  suppressStatistics?: StatisticId[];
  suppressionSets?: SuppressionSet[];
  holdingsIdentifierOverride?: FundId[]; // overrides ISIN with CUSIP
  portfolioHoldingDataPoints?: HoldingTableColumns[];
};

export type StatisticSuppressionSet = {
  id: string;
  funds: FundId[];
  statistics: StatisticId[];
};

export type SuppressionSet = {
  assetClasses: AssetClass[]; // NB: refers to FundCategory in this case
  funds: FundId[];
  id: string;
  portfolioComponents: PortfolioComponentId[];
};

export type PerformanceSuppressionSet = {
  funds: FundId[];
  id: string;
  performanceChart: PerformanceComponentId[];
};

export type PpssConfig = {
  allConfigItem?: SegmentId;
  component?: any[];
  segmentItem?: SegmentId;
  dataRefreshRate?: any;
  primaryFilters?: any; // TODO: check BaseFilter type
  additionalFilters?: any; // TODO: check BaseFilter type
  filtersOptions?: any;
  investmentVehicleList?: string[];
  suppressSearch?: boolean;
  suppressPerformance?: boolean;
  showPerformanceCurrencies?: boolean;
  showDailyAum?: boolean;
  suppressNavChange?: boolean;
  taxonomyList?: any; // need to change to do
  showRatings?: Record<ProductType, boolean>;
  showYields?: Record<ProductType, boolean>;
  showArticle6?: Record<ProductType, boolean>;
  showIdentifiers?: Record<ProductType, boolean>;
  fundIdentifiers?: Record<ProductType, string[]>;
  redirectToPpssUrl?: string;
  hideNavChange?: Record<ProductType, boolean>;
  hideFactsheet?: Record<ProductType, boolean>;
  showFavoriteToggle?: Record<ProductType, boolean>;
  showExcelDownload?: Record<ProductType, boolean>;
  enableExchangeRate?: boolean;
};

export type PriceDistributionConfig = {};
export type SearchConfig = {};

export type SiteConfig = {
  country?: ConfigurationCountry;
  language?: ConfigurationLocale;
};

export type TabSet = {
  name: string;
  tabs: string[];
  page: string;
};

type TabSetOverrideDetail = {
  fundShareClassList: FundShareClassId[];
  id: string;
  tabSet: string;
};

export type ProductTabSet = {
  assignments?: {
    [setName: string]: FundId[];
  };
  sets?: TabSet[];
  overrides?: TabSetOverrideDetail[];
};

export type ProductConfig = {
  general?: GeneralConfig;
  overview?: OverviewConfig;
  performance?: PerformanceConfig;
  portfolio?: PortfolioConfig;
  ppss?: PpssConfig;
  priceDistribution?: PriceDistributionConfig;
  site?: SiteConfig;
  tabs?: Record<ProductType, ProductTabSet>;
};

export type LiteratureConfig = {
  // TODO: define string value types
  pageType?: [
    {
      primaryFilters?: string[];
      additionalFilters?: string[];
      documentTypeDisplay?: string[];
      literatureListGridColumn?: string[];
      literatureDocument?: string[];
      segmentOverrideList?: string[];
      pageTypeName?: string;
    }
  ];
  criteriaType?: [
    {
      documentTypeDisplay?: string[];
      criteriaTypeName?: string;
    }
  ];
  fundDocumentSegmentOverrideList?: string[];
  fundDocument?: string[];
  fundDocumentTab?: string[];
  fundAdditionalResources?: string[];
  fundDocumentGridColumn?: string[];
  litDetailUrl?: string;
  litOrderCheckoutUrl?: string;
  litOrderHistoryDetailUrl?: string;
  orderConfirm?: string;
  orderHistoryList?: string;
  orderSuccess?: string;
  orderCheckoutAddress?: string;
  litListing?: string;
  myLiteratureLandingUrl?: string;
  manageEmailPreference?: string;
  activeList?: string[];
  fundOverrideFlag?: boolean;
  documentTypeDisplayForLoggedIn?: string[];
  downloadExcelIdentifiers?: string[];
  documentsWithPreview?: string[];
  languageMapping?: string[];
  components?: LitDocComponent[];
};

export type TabKey = {
  documentTab: string;
  documentTypeDisplay: string[];
};

export type LitDocComponent = {
  documentTab?: string;
  documentTypeDisplay?: string[];
  name?: string;
};

export type CommonConfig = {
  locale?: string;
  dateFormat?: string;
  taxonomyCountry?: string;
  taxonomyLanguage?: string;
  authentication?: string;
  searchHeader?: boolean;
  notificationsEnabled?: boolean;
  authenticationLegacy?: boolean;
  maintenanceMode?: boolean;
  maintenancePageUrl?: string;
  loginMaintenanceNotice?: boolean;
  loginMaintenanceNoticeText?: string;
  enableFavoriteFunds?: boolean;
  multilingualMapping?: boolean;
  hideLoginForm?: boolean;
  hideSignInWidget?: boolean;
  servicingMaintenance?: boolean;
};

export type SiteParams = {
  countryHubLink?: string;
  footerLogo?: string;
  footerMicrositeTextLogo?: string;
  footerDisclaimerOverride?: string;
  headerLogo?: string;
  logoAltText?: string;
  companyInfo?: CompanyInfo;
  isLeavingSiteForCountryLinkClickDisplayed?: boolean;
  isMicrosite?: boolean;
  headerMicrositeTextLogo?: string;
  headerMicrositeLogoHref?: string;
  personalizeBannerTitleOverride?: string;
  personalizeBannerPreTitleOverride?: string;
  personalizeBannerTitleOverrideLoggedIn?: string;
  personalizeBannerPreTitleOverrideLoggedIn?: string;
  summary?: string;
  summaryLoggedIn?: string;
  insightCardInfo?: InsightCardInfo;
  isSocialMediaSharingButtonsDisabled?: boolean;
  personalizeBannerImage: string;
  personalizeBannerTransparencyRatio: '40' | '60' | '50';
  personalizeBannerTransparency: boolean;
  personalizedContentParams?: string;
};

export interface InsightCardInfo {
  viewMoreUrl: string;
}

export interface TaxInfoConfig {
  filters?: string[];
  productTypefilters?: string[];
}

export interface LiteratureAuditConfig {
  documentTypeDisplay?: string[];
  '529 Portfolios'?: OptionalDocumentType;
  'Closed-End Funds'?: OptionalDocumentType;
  ETFs?: OptionalDocumentType;
  'Interval Funds'?: OptionalDocumentType;
  'Managed Accounts'?: OptionalDocumentType;
  'Money Market Funds'?: OptionalDocumentType;
  'Mutual Funds'?: OptionalDocumentType;
  TIF?: OptionalDocumentType;
  'Variable Insurance'?: OptionalDocumentType;
}

export interface OptionalDocumentType {
  optionalDocumentType?: string;
}

export interface LoginMaintenanceSettings {
  showNotice?: boolean;
  noticeText?: string;
}

export interface DistributionPerShare {
  funds?: FundId[];
  hideDistributionPerShare?: boolean;
  hideRatesAndYieldsForSelectedFunds?: boolean;
}

export interface CustomHeader {
  siteMenu: string;
  document: string;
  logoImage?: string;
  logoAlt?: string;
  searchModalBoxLabelOverride?: string;
  headerSearchBoxLabelOverride?: string;
}
