import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Component as BrComponent, Page } from '@bloomreach/spa-sdk/';
import {
  Chip,
  ChipFilter,
  DropdownItem,
  PaginationComponent,
  ResponsiveService,
} from '@frk/eds-components';
import { AppStateService, WindowScrollService } from '@services';
import { LinkService } from '@services/link.service';
import { TranslateService } from '@shared/translate/translate.service';
import { Link } from '@types';
import { Observable } from 'rxjs';
import {
  CouldFieldData,
  CourseExcelData,
  Courses,
  Filter,
  ValueSet,
} from './../events.type';
import cloneDeep from 'lodash/cloneDeep';
import { LabelLoader } from '@utils/label-loader';
import { EMDASH } from '@products/utils/constants/product.constants';

@Component({
  selector: 'ft-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss'],
})
export class CoursesComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page!: Page;

  @ViewChild(PaginationComponent, { static: false })
  paginationComponent: PaginationComponent;
  private numberOfItems: number[] = [6, 9, 12];
  public totalPages: number;
  public totalRecords: number;
  public pageSize = { value: 6, label: '6' };
  public itemsPerPageOption: DropdownItem[] = [];
  public isMobile$: Observable<boolean>;
  public isHandheld: boolean;
  public pageTitle: string;
  private chipMap: Map<string, boolean>;
  public filters: Filter[] = [];

  public courses: Courses[];
  public filteredCourses: Courses[] = [];
  public chipsFilters: ChipFilter[] = [];
  public visibleCourses: Courses[] = [];
  public showDigitalCatalogs = false;
  public hideFilterValuesFromCard = false;
  public hidePagination = false;
  public showFilters = true;
  public isCategoryLink = false;
  public exportToXlsButtonText: string;
  public hasExportToXlsButton = false;
  /**
   * Object for translation labels from resource bundle
   */
  private translationLabelsDTO: Record<string, string>;
  public excelFilterdData: CourseExcelData[];

  constructor(
    private linkService: LinkService,
    private appStateService: AppStateService,
    private translateService: TranslateService,
    private responsiveService: ResponsiveService,
    private titleService: Title,
    private cd: ChangeDetectorRef,
    private scrollService: WindowScrollService,
    private labelLoader: LabelLoader
  ) {}

  ngOnInit(): void {
    this.hidePagination = this.component?.getParameters()?.hidePagination;
    this.showFilters = !(this.component?.getParameters()?.hideFilters ?? false);
    this.isCategoryLink = this.component?.getParameters()?.isCategoryLink;
    this.isMobile$ = this.responsiveService.isMobile$();
    this.responsiveService.isHandheld$()?.subscribe((isTablet: boolean) => {
      this.isHandheld = isTablet;
    });
    this.pageSize = {
      value: this.component?.getParameters()?.pagination || 6,
      label: this.component?.getParameters()?.pagination || '6',
    };
    this.translationLabelsDTO = this.component?.getModels()[
      'ft.core.eventInformation'
    ];
    this.labelLoader.appendComponentTranslations(
      this.translationLabelsDTO,
      'course'
    );
    this.exportToXlsButtonText = this.translateService.instant(
      'common.export-to-xls'
    );
    this.pageTitle = this.titleService.getTitle();
    this.setCoursesAndFilterData();
    this.filteredCourses = [...this.courses];
    if (this.showFilters) {
      this.filters = this.filters.filter((filter) => filter.items.length > 0);
    }
    this.createDropDownNoOfItems();
    this.updatePagination();
  }

  private setFilterData(): void {
    const allCategories: string[] = [];
    const allApprovals: string[] = [];
    const allLanguages: string[] = [];

    this.courses?.forEach((course: Courses) => {
      allCategories.push(...course.categories);
      course.approvals?.forEach((approval) =>
        allApprovals.push(approval.accreditation)
      );
      allLanguages.push(...course.languages);
    });

    this.filters = [
      this.createFilter('accreditation', 'common.accreditation', allApprovals),
      this.createFilter(
        'categories',
        'common.approval-categories',
        allCategories
      ),
      this.createFilter('languages', 'common.languages', allLanguages),
    ];
  }

  private setFilterDataDigitalCatalog(): void {
    const allTopics: string[] = [];
    const allAudience: string[] = [];
    const allCollections: string[] = [];
    const fourthFilterValue: string[] = [];
    this.courses?.forEach((course: Courses) => {
      course.topics?.forEach((topic) => allTopics.push(topic.accreditation));

      allAudience.push(...course.audience);
      allCollections.push(...course.collections);
      if (course?.fourthFilterValue) {
        fourthFilterValue.push(...course?.fourthFilterValue);
      }
    });

    this.filters = [
      this.createFilter('topics', 'common.topics', [...new Set(allTopics)]),
      this.createFilter('audience', 'common.audience', [
        ...new Set(allAudience),
      ]),
      this.createFilter(
        'collections',
        'common.collections',
        [...new Set(allCollections)],
        'right'
      ),
    ];

    // DCE-2487 Digital Catalog - Add 4th optional filter
    if (fourthFilterValue.length) {
      this.filters.push(
        this.createFilter('fourthFilterValue', 'common.fourthFilterValue', [
          ...new Set(fourthFilterValue),
        ])
      );
    }
  }

  private createFilter(
    name: string,
    title: string,
    items: string[],
    alignment?: string
  ): Filter {
    return {
      name,
      title: this.translateService.instant(title),
      label: this.translateService.instant(title),
      selectedText: 'selected',
      items: this.createFilterDropdown(items),
      alignment,
    };
  }

  private createFilterDropdown(values: string[]): DropdownItem[] {
    return values
      .sort()
      .filter((val, index, self) => self.indexOf(val) === index)
      .map((val) => ({
        label: val,
        count: '',
        checked: false,
        value: val?.trim(),
        id: `option-${val?.trim().toLowerCase().replace(/\s+/g, '-')}`,
      }));
  }

  /**
   * Get Events from content
   */
  public setCoursesAndFilterData() {
    const document = this.component?.getModels<DocumentModels>()?.document;
    const content = document && this.page.getContent(document);
    const courseData = content?.getData();
    if (courseData?.courseCard) {
      this.courses =
        courseData?.courseCard?.map((course) => ({
          courseTitle: course.courseTitle,
          description: course.shortDescription,
          approvals: this.getValueSet(course.approvals),
          categories: course.categories.map((val) =>
            this.translateService.instant(`course.${val}`)
          ),
          languages: course.language.map((val) =>
            this.translateService.instant(`course.${val}`)
          ),
          links: this.getLinks(course?.button),
        })) || [];
      this.setFilterData();
    }

    if (courseData?.digitalCatalogCard) {
      this.showDigitalCatalogs = true;
      this.hideFilterValuesFromCard = courseData?.hideFiltervalues;
      this.hasExportToXlsButton = courseData?.hasExportToXls;
      this.courses =
        courseData?.digitalCatalogCard?.map((course) => ({
          courseTitle: course.digitalCatalogTitle,
          description: course.shortDescription,
          audience: course.audience.map((val) =>
            this.translateService.instant(`course.${val}`)
          ),
          collections: course.collections.map((val) =>
            this.translateService.instant(`course.${val}`)
          ),
          topics: this.getValueSet(course.topics),
          links: this.getLinks(course?.button),
        })) || [];
      if (this.showFilters) {
        this.setFilterDataDigitalCatalog();
      }
    }
  }

  private getValueSet(inputArray: any[]): ValueSet[] {
    return (
      inputArray?.map((input) => {
        const [accreditation, value] = input?.split(':');
        return {
          accreditation:
            this.translateService.instant(`course.${accreditation}`) || '',
          value: value || '',
        };
      }) || []
    );
  }

  public addChips(): ChipFilter[] {
    this.chipMap = new Map();
    this.filters = cloneDeep(this.filters);
    const selectedFilters = this.filters
      .map((filter) => {
        return {
          ...filter,
          items: filter.items.filter((filterItem) => filterItem.checked),
        };
      })
      .filter((filter) => filter.items.length > 0)
      .map((filter) => {
        this.chipMap.set(filter.name, true);
        return {
          chips: filter.items.map((filterItem) => ({
            label: filter.title,
            value: filterItem.label,
          })),
        };
      });

    return selectedFilters;
  }

  public onSelectFilter(): void {
    this.chipsFilters = this.addChips();
    this.updateFilteredCourses();
  }

  private updateFilteredCourses(): void {
    let categoryFilterSet: Set<string>;
    let languageFilterSet: Set<string>;
    let approvalFilterSet: Set<string>;

    let topicsFilterSet: Set<string>;
    let audienceFilterSet: Set<string>;
    let collectionsFilterSet: Set<string>;

    this.filters?.forEach((filter) => {
      if (filter?.name === 'accreditation') {
        approvalFilterSet = this.buildFilterSet(filter?.items);
      }

      if (filter?.name === 'categories') {
        categoryFilterSet = this.buildFilterSet(filter?.items);
      }

      if (filter?.name === 'languages') {
        languageFilterSet = this.buildFilterSet(filter?.items);
      }

      if (filter?.name === 'topics') {
        topicsFilterSet = this.buildFilterSet(filter?.items);
      }

      if (filter?.name === 'audience') {
        audienceFilterSet = this.buildFilterSet(filter?.items);
      }

      if (filter?.name === 'collections') {
        collectionsFilterSet = this.buildFilterSet(filter?.items);
      }
    });
    this.filteredCourses = [
      ...this.courses.filter((course) => {
        const isCategoryValid =
          !this.chipMap.get('categories') ||
          course.categories?.some((category) =>
            categoryFilterSet.has(category)
          );

        const isLanguageValid =
          !this.chipMap.get('languages') ||
          course.languages?.some((language) => languageFilterSet.has(language));

        const isAccreditationValid =
          !this.chipMap.get('accreditation') ||
          course.approvals?.some((approval) =>
            approvalFilterSet.has(approval?.accreditation)
          );

        const isTopicsValid =
          !this.chipMap.get('topics') ||
          course.topics?.some((topic) =>
            topicsFilterSet.has(topic?.accreditation)
          );

        const isAudienceValid =
          !this.chipMap.get('audience') ||
          course.audience?.some((aud) => audienceFilterSet.has(aud));

        const isCollectionsValid =
          !this.chipMap.get('collections') ||
          course.collections?.some((collection) =>
            collectionsFilterSet.has(collection)
          );

        if (this.showDigitalCatalogs) {
          return isTopicsValid && isAudienceValid && isCollectionsValid;
        }

        return isCategoryValid && isLanguageValid && isAccreditationValid;
      }),
    ];
    this.updatePagination();
  }

  private buildFilterSet(filters: DropdownItem[]): Set<string> {
    const checkedFilters = filters?.filter((filter) => filter?.checked);
    if (checkedFilters?.length === 0) {
      return new Set(filters?.map((filter) => filter?.value));
    } else {
      return new Set(checkedFilters?.map((filter) => filter?.value));
    }
  }

  private resetToDefault(): void {
    this.filteredCourses = this.courses;
    this.filters = this.filters.map((filter) => {
      return {
        ...filter,
        items: filter?.items?.map((filterItem) => {
          return {
            ...filterItem,
            checked: false,
          };
        }),
      };
    });
    this.chipsFilters = [];
    this.updatePagination();
  }

  public resetFilter($event: any): void {
    this.resetToDefault();
  }

  public chipRemoved(chip: Chip): void {
    const removedFilterGroup = this.filters.find(
      (filter) => filter.title === chip.label
    );
    if (removedFilterGroup) {
      removedFilterGroup?.items?.forEach((item) => {
        if (item?.label === chip?.value) {
          item.checked = false;
        }
      });
      if (!removedFilterGroup?.items?.find((item) => item.checked)) {
        this.chipMap.delete(removedFilterGroup?.name);
      }
    }
    this.filters = this.filters.map((filter) => {
      return {
        ...filter,
        items: [...filter.items],
      };
    });
    this.chipsFilters = this.chipsFilters.filter(
      (chipFilter) => chipFilter?.chips?.length > 0
    );
    this.updateFilteredCourses();
  }

  public onDropdownChange(numberOfRowsEvent): void {
    this.pageSize = this.itemsPerPageOption.find(
      (options) => Number(options.label) === numberOfRowsEvent.value
    );
    this.updatePagination();
  }

  public goToPage(pageEvent): boolean {
    if (pageEvent.page && pageEvent.action !== 'load') {
      this.visibleCourses = this.filteredCourses.slice(
        (pageEvent.page - 1) * this.pageSize.value,
        pageEvent.page * this.pageSize.value
      );
      this.scrollService.scrollToAnchor('courseScrollPosition');
      return true;
    }
  }

  /**
   * Get Links
   */
  public getLinks = (linkRef): Link => {
    let links: any = {};
    if (linkRef) {
      linkRef.forEach((link) => {
        const linkCollection =
          link?.linkCompound?.linkCollection || link?.linkCollection;
        if (link.buttonStyle !== 'secondary') {
          links = linkCollection[0];
          if (links) {
            links.presentationLinkURL = this.linkService?.getCTALink(
              this.page,
              linkCollection[0],
              this.appStateService?.getSpaBaseUrl()
            );
          }
        } else {
          links.optionalLink = linkCollection[0];
          if (links) {
            links.optionalLink.optionalLinkUrl = this.linkService?.getCTALink(
              this.page,
              linkCollection[0],
              this.appStateService?.getSpaBaseUrl()
            );
          }
        }
      });
    }

    return links;
  };

  public updateFilter(category: string): void {
    // this.resetToDefault();
    this.filters
      .find((filter) => filter.name === 'categories')
      .items.find((item) => item.value === category).checked = true;
    this.onSelectFilter();
  }

  public updateDigitalCatalogFilter(data: CouldFieldData): void {
    // this.resetToDefault();
    this.filters
      .find((filter) => filter.name === data.field)
      .items.find((item) => item.value === data.type).checked = true;
    this.onSelectFilter();
  }

  /**
   * Creates dropdown options for number of items.
   */
  private createDropDownNoOfItems(): void {
    this.itemsPerPageOption = this.numberOfItems.map((rows) => ({
      value: rows,
      label: String(rows),
    }));
  }

  private setTotalRecords(): void {
    this.totalRecords = this.filteredCourses.length;
  }

  private updatePagination(): void {
    this.setTotalRecords();
    this.totalPages = Math.ceil(this.totalRecords / this.pageSize?.value);
    if (!this.hidePagination) {
      this.visibleCourses = this.filteredCourses.slice(0, this.pageSize?.value);
      if (this.paginationComponent) {
        this.paginationComponent.activePage = 1;
      }

      this.paginationComponent?.updatePagination(this.totalPages);
    } else {
      this.visibleCourses = this.filteredCourses;
    }
    this.cd.detectChanges();
  }

  public configureExportToXls(): void {
    this.excelFilterdData = this.generateExcelData();
    import('xlsx').then((xlsx) => {
      // Create a new workbook and a worksheet
      const workbook = xlsx.utils.book_new();
      const worksheet = xlsx.utils.json_to_sheet([]);

      // Addthe "As of Date" line
      xlsx.utils.sheet_add_aoa(
        worksheet,
        [['As of Date ' + new Date().toLocaleDateString()]],
        { origin: 'A1' }
      );

      // Adding Table Headings
      const headers = [
        [
          this.translateService.instant('common.program-name'),
          this.translateService.instant('common.program-description'),
          this.translateService.instant('common.topics'),
          this.translateService.instant('common.audience'),
          this.translateService.instant('common.collections-with-bracket'),
        ],
      ];
      xlsx.utils.sheet_add_aoa(worksheet, headers, { origin: 'A2' });

      xlsx.utils.sheet_add_json(worksheet, this.excelFilterdData, {
        origin: 'A3',
        skipHeader: true,
      });

      worksheet['!cols'] = Array(headers[0].length).fill({ wch: 30 });

      // Adding the worksheet to workbook
      xlsx.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');

      xlsx.writeFile(
        workbook,
        this.translateService.instant('common.digital-catalogue') + '.xlsx'
      );
    });
  }

  private generateExcelData(): CourseExcelData[] {
    const excelData: CourseExcelData[] = [];
    this.filteredCourses.forEach((course) => {
      const paragraphDescription = course?.description;
      const div = document.createElement('div');
      div.innerHTML = paragraphDescription;
      const courseDescription = div.textContent || div.innerText || EMDASH;
      let courseCategory = EMDASH;
      let courseAudience = EMDASH;
      let courseName = EMDASH;
      let courseCollection = EMDASH;
      // Adding Course Title
      if (course?.courseTitle) {
        const span = document.createElement('span');
        span.innerHTML = course?.courseTitle;
        courseName = span.textContent || span.innerHTML || EMDASH;
      }
      // Adding Course Topics
      if (course?.topics?.length) {
        courseCategory = course?.topics[0]?.accreditation;
      }
      // Adding Course Audience
      if (course?.audience?.length) {
        courseAudience = course?.audience.join(', ');
      }
      // Adding Course Collections
      if (course?.collections?.length) {
        courseCollection = course?.collections.join(', ');
      }
      // Pushing data into the excel data type.
      excelData.push({
        programName: courseName,
        programDescription: courseDescription,
        category: courseCategory,
        audience: courseAudience,
        collection: courseCollection,
      });
    });
    return excelData;
  }
}
